import * as React from 'react'
import * as Base from 'react-festival/build/ticketing/list-content'
import { FormattedMessage } from 'react-intl'
import { inject } from 'mobx-react'
import { PageTicketing, DocumentLink, Price, VipContent } from '@festival/entities/page-configuration'
import { Col, Row, Card, CardTitle, Alert, Button } from 'reactstrap'
import cn from 'classnames'
import Manifest from '@festival/manifest'

export interface Props extends Base.Props {
    manifest?: Manifest
}

export class ListContent extends Base.ListContent<Props> {
    protected renderContent(details: PageTicketing): React.ReactNode {
        const { className } = this.props

        return (
            <div id="ticketing" className={cn(className)}>
                <Row>
                    <Col className="border-right border-warning border-bottom" xl={6}>
                        { this.renderPrices(details.prices) }
                    </Col>
                    <Col className="border-warning border-bottom" xl={6}>
                        { this.renderBooks(details.books, details.booksNotes) }
                    </Col>
                </Row>
                { this.renderVip(details.vipContent, details.vipContentNotes) }
            </div>
        )
    }

    protected get titlePrefix (): React.ReactNode {
        return <i className="mdi mdi-pound ml-1"></i>
    }

    protected renderPricesContent (prices: Price[] | undefined): React.ReactNode {
        const { manifest } = this.props

        if (!manifest) {
            return null
        }

        return (
            <Row>
                <Col xs="auto">
                    <img src={manifest.get('img/picto-pass.png')} className="picto" alt="Informations"/>
                </Col>
                <Col>
                    { super.renderPricesContent(prices) }
                </Col>
            </Row>
        )
    }

    protected renderBooksContent (books: DocumentLink[] | undefined, booksNotes: string | undefined): React.ReactNode {
        const { manifest } = this.props

        if (!manifest) {
            return null
        }

        if (!books && !booksNotes) {
            return null
        }

        return (
            <>
                <Row>
                    <Col xs="auto">
                        <img src={manifest.get('img/picto-pass.png')} className="picto" alt="Réservations"/>
                    </Col>
                    <Col className="d-flex flex-column">
                        { books && books.length > 0 && (
                            <>
                                { books.map((document: DocumentLink, index: number) => (
                                    <Button
                                        key={index}
                                        color="primary"
                                        tag="a"
                                        href={document.available ? document.link : '#'}
                                        target="_blank"
                                        rel="noreferrer"
                                        disabled={!document.available || !document.link}
                                        className="mb-2"
                                        >
                                        { document.title }
                                    </Button>
                                ))}
                            </>
                        )}
                    </Col>
                </Row>
                { (booksNotes) && (
                    <Alert color="info" className="mt-2">
                        { booksNotes }
                    </Alert>
                )}
            </>
        )
    }

    protected renderVipContent (vipContent: VipContent[] | undefined, vipContentNotes: string | undefined): React.ReactNode {
        const { manifest } = this.props

        if (!manifest) {
            return null
        }

        return (
            <Row>
                <Col xs="auto">
                    <img src={manifest.get('img/picto-pass-vip.png')} className="picto" alt="Contenu du pas"/>
                </Col>
                <Col>
                    { super.renderVipContent(vipContent, vipContentNotes) }
                </Col>
            </Row>
        )
    }
}

export default inject('pageConfigurationStore', 'routeManager', 'manifest')(ListContent)
